@import "../helpers/style-helpers/mixins";

@import url('https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');// rel='stylesheet' type='text/css'>
// @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

/* latin-ext 

@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2') format('woff2');
  //src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 /*latin 
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2') format('woff2');
  //src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext *//*
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2') format('woff2');
  //src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin *//*
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/S6uyw4BMUTPHjx4wXiWtFCc.woff2') format('woff2');
  //src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/
/* latin-ext */
/*
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2') format('woff2');
  //src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
/* latin */
/*
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2') format('woff2');
  //src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/
/* latin-ext */
/*
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2') format('woff2');
  //src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
*/
/* latin */
/*
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2') format('woff2');
  //src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
*/
*, :before, :after {
  //position: relative;

  box-sizing: border-box;

 // font-family: 'Lato', sans-serif;
  text-align: left;

  -webkit-overflow-scrolling: touch;
}

button, input, textarea, ul, li {
  display: block;
  margin: 0; padding: 0;

  background-color: transparent;
  border: none;

  text-decoration: none;
  list-style: none;
}

body.using-mouse button:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0; padding: 0;

  font-weight: normal;
  line-height: 1;
}

a {
  margin: 0; padding: 0;

  color: inherit;
  text-decoration: none;

  &:hover, &:active, &:focus, &:visited {
    text-decoration: none;
  }
}

img {
  display: block;
}

html, body, #root {
  @include size(100%);

  margin: 0; padding: 0;

  background-color: white;
}

.ag-header-row-floating-filter {
  background-color: white;
  .ag-header-cell{
    border-top: none !important;
    border-right: 0.5px solid #e2e2e2;
  }
}

.outsideBoundaries {
  // background-color: #FFE5E5;
  color: red;
}

.ag-theme-alpine .ag-radio-button-input-wrapper.ag-checked:after {
  color: #14869C !important;
}

.ag-theme-alpine .ag-tab-selected {
  color: #14869C !important;
}

.ag-theme-alpine .ag-tab-selected {
  border-bottom-color: #14869C !important;
}

.ag-theme-alpine .ag-tabs-header {
  background-color: #F2F2F9;
}

.ag-theme-alpine .ag-menu, .ag-theme-alpine .ag-menu-header {
  background-color: #fff !important; 
}

.ag-theme-alpine .ag-picker-field-wrapper {
  border-bottom: 1px solid #DEE2E7 !important;
  border-radius: 0px !important;
}

.ag-theme-alpine input[class^="ag-"]:not([type]), .ag-theme-alpine input[class^="ag-"][type="date"], .ag-theme-alpine input[class^="ag-"][type="datetime-local"], .ag-theme-alpine input[class^="ag-"][type="number"], .ag-theme-alpine input[class^="ag-"][type="tel"], .ag-theme-alpine input[class^="ag-"][type="text"], .ag-theme-alpine textarea[class^="ag-"] {
  border-bottom: 1px solid #DEE2E7 !important;
  font-family: Lato;
}

.ag-theme-alpine .ag-overlay-loading-center {
  font-family: Lato;
}

input[class^="ag-"]:not([type]), .ag-theme-alpine input[class^="ag-"][type="date"], .ag-theme-alpine input[class^="ag-"][type="datetime-local"], .ag-theme-alpine input[class^="ag-"][type="number"], .ag-theme-alpine input[class^="ag-"][type="tel"], .ag-theme-alpine input[class^="ag-"][type="text"], .ag-theme-alpine textarea[class^="ag-"] {
  border-radius: 0px !important;
}