@import "./functions";

@mixin size($width:null, $height:null) {
  @if $width {
    width: $width;
  } @else {
    width: inherit;
    height: inherit
  }

  @if $height {
    height: $height;
  } @else if $width {
    height: $width;
  }
}

@mixin widthSpecifications($minW: null, $maxW: null) {
  min-width: $minW;
  max-width: $maxW;
}

@mixin heightSpecifications($minH: null, $maxH: null) {
  min-height: $minH;
  max-height: $maxH;
}

@mixin truncateString() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin clampString($line: 1) {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin font($fz:null, $c:null, $lh:null, $fw:null) {
  font-size: $fz;
  color: $c;
  font-weight: $fw;

  @if $lh {
    $getNum: $lh / 100;

    @if round($getNum) == $getNum {
      font-weight: $lh;
    } @else {

      line-height: $lh;
    }
  }
}

@mixin svg-color($fill:$deepBlue-100, $stroke:$fill) {
	fill: $fill;
	stroke: $stroke;
}

@mixin styleIfNumberOfElements($numberOfElements) {
  @if $numberOfElements == 1 {
    &:first-of-type:nth-last-of-type(1) {
      @content;
    }
  } @else if $numberOfElements > 1 {
    &:first-of-type:nth-last-of-type(#{$numberOfElements}),
    &:first-of-type:nth-last-of-type(#{$numberOfElements}) ~ & {
      @content;
    }
  }
}

@mixin styleNumberOfFirstElements($numberOfElements) {
  &:nth-child(-n+#{$numberOfElements}) {
    @content;
  }
}

@mixin flexContainer($direction: 'row', $directionHorizontal: null, $directionVertical: null, $selectorsToIgnoreFlex: null) {

  @if $direction == center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @if $direction != null and $direction != center {
   display: flex;
  }

  @if $direction == row {
    @if $directionHorizontal == left {
      justify-content: flex-start;
    } @else if $directionHorizontal == center {
      justify-content: center;
    } @else if $directionHorizontal == right {
      justify-content: flex-end;
    } @else if $directionHorizontal == between {
      justify-content: space-between;
    } @else if $directionHorizontal == around {
      justify-content: space-around;
    }

    @if $directionVertical == top {
      align-items: flex-start;
    } @else if $directionVertical == center {
      align-items: center;
    } @else if $directionVertical == bottom {
      align-items: flex-end;
    } @else if $directionVertical == stretch {
      align-items: stretch;
    }
  } @else if $direction == column {
    flex-direction: column;

    @if $directionHorizontal == left {
      align-items: flex-start;
    } @else if $directionHorizontal == center {
      align-items: center;
    } @else if $directionHorizontal == right {
      align-items: flex-end;
    }

    @if $directionVertical == top {
      justify-content: flex-start;
    } @else if $directionVertical == center {
      justify-content: center;
    } @else if $directionVertical == bottom {
      justify-content: flex-end;
    } @else if $directionVertical == between {
      justify-content: space-between;
    } @else if $directionVertical == around {
      justify-content: space-around;
    }
  }

  @if  $selectorsToIgnoreFlex {
    @if length($selectorsToIgnoreFlex) == 1 {
      & > #{$selectorsToIgnoreFlex} {
        flex: none;
      }
    } @else {
      @each $item in $selectorsToIgnoreFlex {
        & > #{$item} {
          flex: none;
        }
      }
    }
  }

  @content;
}

@mixin absoluteAlign($axis: null, $side: null, $addToTransform: null) {
  position: absolute;

  @if $axis == null {
    left: 50%; top: 50%;
    transform: translate(-50%, -50%) #{$addToTransform};
  } @else {
    @if $axis == horizontal {
      left: 50%;
      @if $side {
        #{$side}: 0;
      } @else {
        top: 0;
      }
      transform: translateX(-50%) #{$addToTransform};
    } @else if $axis == vertical {
      top: 50%;
      @if $side {
        #{$side}: 0;
      } @else {
        left: 0;
      }
      transform: translateY(-50%) #{$addToTransform};
    }
  }
}
