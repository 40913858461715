@import "../../../helpers/style-helpers/_mixins";
@import "../../../helpers/style-helpers/variables";

/*
==========================================================================

BLOCKS

==========================================================================
*/

.spinner {
	@include size(66px);
	
	animation: containerAnimation 2s linear infinite;
}

/*
==========================================================================

ELEMENTS

==========================================================================
*/

$loader-colors: #439EB0, #439EB0, #439EB0, #439EB0;

.container__loader.graph-loader {
  height: auto;
}
.container__loader.small-widget-loader {
  height: 200px
}
.container {

  &__loader {
    @include size(100%);
    @include flexContainer(center);

    opacity: 0;
    transform: scale(5);
    animation: animateLoaderIn .75s ease forwards;
    height: 100vh;
  }

  &__loader-bar {
    @include size(4px, 18px);

    margin: 0 2px;

    border-radius: 4px;
    animation: fetchLoader 1s ease-in-out infinite;

    @for $i from 1 through 4 {
      $delay: calc(.09s * #{$i} - .09s);
      $bgColor: nth($loader-colors, $i);

      &:nth-child(#{$i}) {
        background-color: $bgColor;
        animation-delay: #{$delay};
      }
    }
  }
}

$colors: #84EBBD, #4977EC, #F6BB67, #333841;
$d: 175.6449737548828;

.spinner {
  $b: &;

  &__svg {
    @include size(100%);
	
    left: 0; top: 0;
    position: absolute;
    
    transform: rotateZ(-90deg);

    @for $i from 1 through 4 {		
      &:nth-child(#{$i}) > #{$b}__path {
        stroke: nth($colors, $i);
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
        
        animation: storeAnimation 3s calc(.2s * (#{$i})) ease infinite;
        transform: translateZ(0.01px);
        transform-origin: center center;

        will-change: stroke-dasharray, stroke-dashoffset;
      }
    }
  }
}

/*
==========================================================================

ANIMATIONS

==========================================================================
*/

@keyframes storeAnimation {
	0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d / 3;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d;
  }
}

@keyframes containerAnimation {
	100% {
		transform: rotateZ(360deg) translateZ(0.01px)
	}
}

@keyframes fetchLoader {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes animateLoaderIn {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
