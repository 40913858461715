$bg: rgba(#4281F7, .04);
$white: white;
$lightGray: #C2C2C2;
$darkGray: #484848;
$darkBlue: #0C0033;
$warning: #FFC000;
$active: #13AD06;
$issue: #E10000;

$brandColor: #14869C;
