@import "../../helpers/style-helpers/mixins";
@import "../../helpers/style-helpers/variables";

/*
==========================================================================

BLOCKS

==========================================================================
*/

.root {
  $b: &;
  $selectorsToIgnoreFlex: '#{$b}__header';

  @include size(100%);
  @include flexContainer(column, $selectorsToIgnoreFlex: $selectorsToIgnoreFlex);

  background-color: #f2f2f7;
  font-family: 'Lato';
}

.bg-overlay {
  @include size(100%);

  position: fixed;
  left: 0; top: 0;

  background-color: rgba(red, 0);

  z-index: 2;
}

/*
==========================================================================

ELEMENTS

==========================================================================
*/

#root{
  height: auto;
  min-height: 100vh;
}

.root {

  height: auto;
  min-height: 100vh;

  $b: &;

  .container{
    min-height: calc(100vh - 40px);
  }

  &__header {
    @include size(100%, auto);
  }

  &__container {
    $selectorsToIgnoreFlex: '#{$b}__tabs';

    @include size(100%);
    @include flexContainer(row, $selectorsToIgnoreFlex: $selectorsToIgnoreFlex);
  }

  &__tabs {
    @include size(auto, 100%);
  }

  &__wrapper {
    overflow: hidden;
    @include size(100%);
  }

  &__content {
    @include size(100%);

    // position: absolute;
    // left: 0; top: 0;

    // background-color: $bg;
  }
}

/* 
==========================================================================

Accordion Styles

==========================================================================
*/

.accordion__title {
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
}

*:focus {
  outline: none;
}


.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  // top: 50%;
  // left: 3%;
  // margin-top: -6px;
  color: $brandColor;
}

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

.accordion__arrow::before {
  left: 4px;
  transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
  transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
  transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* 
==========================================================================

Plot Styles

==========================================================================
*/

.js-plotly-plot {
  width:100%;
}

/* 
==========================================================================

Button Styles

==========================================================================
*/

button {
  cursor: pointer;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes move-down {
  0% {
      transform: translateY(0);
  }
  10% {
      transform: translateY(0);
  }
  20% {
      transform: translateY(5px);
  }
  30% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(0);
  }
}

@keyframes move-up {
  0% {
      transform: translateY(0);
  }
  10% {
      transform: translateY(0);
  }
  20% {
      transform: translateY(-5px);
  }
  30% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(0);
  }
}

.accordion__title--animated:hover .accordion__arrow {
  animation-name: move-down;
  animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
  animation-name: move-up;
  animation-duration: 1.5s;
}

/*
==========================================================================

ANIMATIONS

==========================================================================
*/

.fade-enter {
  position: absolute;
  left: 0; top: 0;


  opacity: 0;
  z-index: 1;

  transform: translateZ(0.01px);

  will-change: opacity, transform;
}

.fade-enter.fade-enter-active {
  opacity: 1;

  transform: translateZ(0.01px);
  transition: .75s ease;

  will-change: opacity, transform;
}

.fade-exit {
  position: absolute;
  left: 0; top: 0;

  opacity: 1;
  transition: .75s ease;

  transform: translateZ(0.01px);
  will-change: opacity, transform;
}

.fade-exit-active {
  opacity: 0;
  z-index: 1;

  transform: translateZ(0.01px);
  transition: .75s ease;

  will-change: opacity, transform;
}
